import {
  BANK_ACC_REGEX,
  ALPHABET_REGEX,
  // PANCARD_REGEX,
  ADDRESS_REGEX,
  PINCODE_REGEX,
  MOBILE_REGEX,
  // AADHAR_RGEX,
  IFSC_REGEX,
} from "@/utils/RegExpConst";
import Vue from "vue";
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators'
export {
  required
}

export const validate = (_text, _type) => {
  if (!_text) return false;
  return new RegExp(_type).test(_text);
};

/**
 * Ensures that user inputs when signing up are correct.
 */
export const validateRegistrationInputs = (_this) => {
  const {
    // aadhaarNumberProof,
    // pancardNumberProof,
    // aadhaarCardRear,
    // pancardNumber,
    // aadhaarNumber,
    mobileNo,
    pincode,
    address,
    name,
    city,
    state
  } = _this.form;
  const errors = {};
  // validating name field
  if (!validate(city, ALPHABET_REGEX))
    errors.city = _this.$t("_pleaseEnterCity");

  if (!validate(state, ALPHABET_REGEX))
    errors.state = _this.$t("_pleaseEnterState");

  if (!validate(name, ALPHABET_REGEX))
    errors.name = _this.$t("_pleaseEnterName");

  // validating mobileNo field
  if (!validate(mobileNo, MOBILE_REGEX))
    errors.mobileNo = _this.$t("_pleaseEnerValidNo");

  // validating address field
  if (!validate(address, ADDRESS_REGEX))
    errors.address = _this.$t("_pleaseEnterValidAddress");

  // validating aadhar number
  // if (!validate(aadhaarNumber, AADHAR_RGEX))
  //   errors.aadhaarNumber = _this.$t("_pleaseEnterValidAadhar");

  // // validating pan number
  // if (!validate(pancardNumber, PANCARD_REGEX))
  //   errors.pancardNumber = _this.$t("_pleaseEnterValidPanNo");

  // // validating pan image uploaded or not
  // if (!pancardNumberProof)
  //   errors.pancardNumberProof = _this.$t("_pleaseEnterUploadPanCard");

  // // validating aadhar front image uploaded or not
  // if (!aadhaarNumberProof)
  //   errors.aadhaarNumberProof = _this.$t("_pleaseEnterUploadAadharFrontImage");

  // // validating aadhar rear image uploaded or not bank_name
  // if (!aadhaarCardRear)
  //   errors.aadhaarCardRear = _this.$t("_pleaseEnterUploadAadharBackImage");

  // validating pincode
  if (!validate(pincode, PINCODE_REGEX)) {
    errors.pincode = _this.$t("_pleaseEnterValidPincode");
  }

  if (_this.selectedPincodes.length == 0){
    errors.supportedPincode = _this.$t("_pleaseEnterSupportedPincode")
  }

  return errors;
};

/**
 * Ensures that delivery boy inputs when signing up are correct.
 */
export const validateDeliveryBoyInputs = (_this) => {
  const {
    return_mile,
    first_mile,
    redispatch,
    last_mile,
    password,
    mobileNo,
    name,
  } = _this.form;
  const errors = {};
  // validating name field
  if (!validate(name, ALPHABET_REGEX))
    errors.name = _this.$t("_pleaseEnterName");

  // validating mobileNo field
  if (!validate(mobileNo, MOBILE_REGEX))
    errors.mobileNo = _this.$t("_pleaseEnerValidNo");

  // validating password field
  if (!password) errors.password = _this.$t("_pleaseEnterPassword");

  if (!return_mile && !last_mile && !first_mile && !redispatch) {
    errors.permission = _this.$t("_pleaseSelectPermission");
  }

  return errors;
};

/**
 * Ensures that user inputs when bank detials are correct.
 */
export const validateBankInputs = (inputs) => {
  const { reTypeBankAccNo, bank_account, bank_name, ifsc_code } = inputs;
  const errors = {};

  // validating bank name
  if (!validate(bank_name, ALPHABET_REGEX))
    errors.bank_name = "Please select your bank name";

  // validating bank account no
  if (!validate(bank_account, BANK_ACC_REGEX))
    errors.bank_account = "Please enter valid bank account number";

  // matching bank account no
  if (bank_account !== reTypeBankAccNo)
    errors.reTypeBankAccNo = "Bank account not matched";

  // validating bank ifsc no
  if (!validate(ifsc_code, IFSC_REGEX))
    errors.ifsc_code = "Please enter valid ifsc no";

  return errors;
};

export const validateQrcodeTaskInputsStep1 = (inputs) => {
  const errors = {};
  const {
    name,
    mobileNo,
    address
  } = inputs;

  if(!validate(name, ALPHABET_REGEX))
    errors.name = "Please enter full name";
  
  if(!validate(mobileNo, MOBILE_REGEX))
    errors.mobileNo = "Please enter a valid mobile number";

  if(!validate(address, ""))
    errors.address = "Please enter address";

  return errors;
}

export const validateQrcodeTaskInputsStep2 = (inputs) => {
  const errors = {};
  const {
    shopName,
    pincode,
    address
  } = inputs;

  if(!validate(shopName, ""))
    errors.shopName = "Please enter shop name";

  if(!validate(pincode, PINCODE_REGEX))
    errors.pincode = "Please enter a valid pincode";

  if(!validate(address, ""))
    errors.address = "Please enter address";
}