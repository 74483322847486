export const MOBILE_REGEX = /^[6-9]\d{9}$|^[6-9]\d{9}$/g;
export const ALPHABET_REGEX = /^[a-zA-Z ]*$/g;
export const OTP_REGEX = /^[0-9]{4}/g;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/g;
export const AADHAR_RGEX = /^(\d{12}|\d{16})$/g;
export const BANK_ACC_REGEX = /^[0-9]{9,18}$/g
export const PANCARD_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/g;
export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9]*$/g;
export const ADDRESS_REGEX = /[A-Za-z0-9'.-\s,]$/g
export const BANKACC_REGEX = /^\d{9,18}$/g;
export const IFSC_REGEX = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/g;
export const UPI_RGEX = /^\w+@\w+$/g;